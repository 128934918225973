import './App.css';
import React, { useState } from 'react';
import logo from './logo.png';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyB7cqh_Sorn_JZEFH9dT6C3Ah7d9TxODcU",
  authDomain: "string-chatroom.firebaseapp.com",
  projectId: "string-chatroom",
  storageBucket: "string-chatroom.appspot.com",
  messagingSenderId: "278997866948",
  appId: "1:278997866948:web:fd27ba397591337e3d6c91",
  measurementId: "G-8EDE53X922"
})

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>
        <img class="logo" src={logo} alt="logo" />
        <SignOut s/>
      </header>
      <section>
        {user ? <ChatRoom /> : <SignIn />}
      </section>
    </div>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <button onClick={signInWithGoogle}>Sign in with Google</button>
  )
}

function SignOut() {
  return auth.currentUser && (
    <button class="signout" onClick={() => auth.signOut()}>Sign out</button>
  )
}

function ChatRoom() {
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limit(250);

  const [messages] = useCollectionData(query, {idField: 'id'});

  const [formValue, setFormValue] = useState('');
  const [username, setUsername] = useState('');


  const sendMessage = async(e) => {
    e.preventDefault();

    const { uid } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      username: username
    });

    setFormValue('');
  }

  return (
    <div class = "ChatRoom">
      <div>
        {messages && messages.map(msg => <ChatMessage key = {msg.id} message = {msg} />)}
      </div>

      <div class="messageForm">
        <form onSubmit = {sendMessage}>
          <input class="username" placeholder = "enter your username" value = {username} onChange={(e) => setUsername(e.target.value)}/>
          <input class="messagecontent" placeholder = "send a message..." value = {formValue} onChange={(e) => setFormValue(e.target.value)}/>
          <button type="submit">send!</button>
        </form>
      </div>
    </div>
  )
}

function ChatMessage(props) {
  const { text, uid, username, createdAt } = props.message;
  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'recieved';

  if (username !== '') {
    return (
      <div className='message ${messageClass}'>
        <p>[ {username} ] {text}</p>
      </div>
    )
  }
  return (
    <div className='message ${messageClass}'>
      <p>[ Anonymous {uid} ] {text}</p>
    </div>
  )
}

export default App;
